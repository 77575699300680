import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: "https://teentogether.stackerbee.com",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const meditationApi = createApi({
  reducerPath: 'meditationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMeditationlist: builder.query({
      query: ({ page = 1, pageSize = 10, searchTerm = '' }) => ({
        url: `/meditation/list/`,
        params: {
          page,
          page_size: pageSize,
          search: searchTerm,  // Assuming your API filters by category or search term
        },
      }),
    }),
    postMeditation: builder.mutation({
      query: (data) => ({
        url: `/meditation/create/`,
        method:'POST',
        body:data
      })
    }),
    putMeditation: builder.mutation({
      query: ({data,id}) => ({
        url: `/meditation/meditation/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetMeditationlistQuery,usePostMeditationMutation,usePutMeditationMutation } = meditationApi