import React, { useState } from "react";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { usePostDoctorChangePasswordMutation } from "../../store/api/doctorapi";

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, seterror] = useState("");
    const [success, setsuccess] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();
        seterror("");
        setsuccess("");

        if (newPassword === confirmPassword) {
            try {
                const response = await changepassword({
                    current_password: oldPassword,
                    new_password: newPassword,
                }).unwrap();
                setsuccess("Password changed successfully");
            } catch (err) {
                seterror(err.data?.message || "Failed to change password");
            }
        } else {
            seterror("Passwords do not match");
        }
    };


    const [changepassword] = usePostDoctorChangePasswordMutation()




    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                height: "100vh",
                background: "#f7f8fc",
            }}
        >
            <div
                className="card p-5 shadow-lg"
                style={{
                    position:'relative',
                    top:'-30px',
                    left:'-10px',
                    width: "450px",
                    borderRadius: "20px",
                    background: "white",
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                }}
            >
                <h2
                    className="text-center mb-4"
                    style={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: "#00A192",
                        letterSpacing: "1px",
                    }}
                >
                    Change Password
                </h2>

                <form onSubmit={handleSubmit}>
                    {/* Old Password */}
                    <div className="mb-4 position-relative">
                        <input
                            type="password"
                            className="form-control shadow-sm"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            style={{
                                fontSize: "1.1rem",
                                height: "50px",
                                paddingLeft: "55px",
                                borderRadius: "30px",
                                backgroundColor: "white",
                                border: "1px solid #d1d9e6",
                            }}
                            placeholder="Enter Old Password"
                            required
                        />
                        <FaLock
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                color: "white",
                                background: "#00A192",
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                                padding: "5px",
                            }}
                        />
                    </div>

                    {/* New Password */}
                    <div className="mb-4 position-relative">
                        <input
                            type="password"
                            className="form-control shadow-sm"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            style={{
                                fontSize: "1.1rem",
                                height: "50px",
                                paddingLeft: "55px",
                                borderRadius: "30px",
                                backgroundColor: "white",
                                border: "1px solid #d1d9e6",
                            }}
                            placeholder="Enter New Password"
                            required
                        />
                        <FaLockOpen
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                color: "white",
                                background: "#00A192",
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                                padding: "5px",
                            }}
                        />
                    </div>

                    {/* Confirm Password */}
                    <div className="mb-4 position-relative">
                        <input
                            type="password"
                            className="form-control shadow-sm"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={{
                                fontSize: "1.1rem",
                                height: "50px",
                                paddingLeft: "55px",
                                borderRadius: "30px",
                                backgroundColor: "white",
                                border: "1px solid #d1d9e6",
                            }}
                            placeholder="Confirm Password"
                            required
                        />
                        <FaLock
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                color: "white",
                                background: "#00A192",
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                                padding: "5px",
                            }}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="d-flex justify-content-center">
                        
                    <button
                        type="submit"
                        className="btn w-70"
                        style={{
                            background: "#00A192",
                            border: "none",
                            fontSize: "18px",
                            padding:'0px 23px',
                            height: "50px",
                            borderRadius: "30px",
                            color: "white",
                            fontWeight: "bold",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                        }}
                        >
                        Change Password
                    </button>
                        </div>
                </form>

                <div className="mt-4">
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                    {success && (
                        <div className="alert alert-success" role="alert">
                            {success}
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default ChangePassword;
