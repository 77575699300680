import { NavLink, useNavigate } from "react-router-dom";
import Table from "./Table";
import { useState } from "react";
import Popboxs from "./Popboxs";
import Popboxs2 from "./Popboxs2";

const Userlist = () => {
  const nvg = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const [sidebarVisibletwo, setSidebarVisibletwo] = useState(false);
  const toggleSidebartwo = () => {
    setSidebarVisibletwo(!sidebarVisibletwo);
  };

  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">User List</p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div
                className="addnew d-lg-flex d-none"
                style={{ flexDirection: "column", gap: "4px" }}
              >
                {/* <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    nvg("/add-user");
                  }}
                >
                  <NavLink
                    to="/add-user"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    + Add New
                  </NavLink>
                </button> */}


                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={toggleSidebartwo}
                >
                  + Add Avatars
                </button>
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={toggleSidebar}
                >
                  + Add Concentrate
                </button>
              </div>
            </div>
          </div>

          <div
            className="row bg-white pb-4 rounded-bottom table-responsive"
            style={{ paddingBottom: "7rem" }}
          >
            <Table />

            <Popboxs
            sidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar}
          />
            <Popboxs2
            sidebarVisible={sidebarVisibletwo}
            toggleSidebar={toggleSidebartwo}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
