import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

import Loadercomp from "../../../components/Loadercomp";
import { useGetDoctordetaillistQuery } from "../../../store/api/doctorapi";

const Table = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setdata] = useState([]);
   const [dateFocused, setdateFocused] = useState(false);
    const [fromDateFocused, setFromDateFocused] = useState(false);
    const [toDateFocused, setToDateFocused] = useState(false);
      const [createdAt, setCreatedAT] = useState("");
      const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const [isLoading, setisLoading] = useState(false);
  // const [error, seterror] = useState();

  // Fetch data with dynamic parameters using RTK Query




  const [realfromtime,setrealfromtime] = useState("");
  const [realtotime,setrealtotime] = useState("");

  const handleDateChange = (e) => {
    const value = e.target.value;
    console.log("qwerty",value)
    setCreatedAT(value);
    setCurrentPage(1);
  };
  const handleFromDateChange = (e) => {
    const value = e.target.value;
    console.log("qwefgnm,",value);
// Extract date and time components from the input value
const [datePart, timePart] = value.split("T");
const [year, month, day] = datePart.split("-");
const [hours, minutes] = timePart.split(":");

// Create a UTC date manually
const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, 0));

// Convert to ISO string and remove milliseconds
const utcString = utcDate.toISOString().replace(".000", "");
console.log("Converted UTC date:", utcString);

    setrealfromtime(utcString)
    setFromDate(value);
    setCurrentPage(1);
  };

  const handleToDateChange = (e) => {
    const value = e.target.value;
    // Extract date and time components from the input value
const [datePart, timePart] = value.split("T");
const [year, month, day] = datePart.split("-");
const [hours, minutes] = timePart.split(":");

// Create a UTC date manually
const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, 0));

// Convert to ISO string and remove milliseconds
const utcString = utcDate.toISOString().replace(".000", "");
console.log("Converted UTC date:", utcString);
setrealtotime(utcString)
    setToDate(value);
    setCurrentPage(1);
  };

  const { data :Data , error, isLoading } = useGetDoctordetaillistQuery({
    page: currentPage,
    pageSize,
    realfromtime,
    realtotime,
    createdAt,
  });

  
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); 
  };

  const handlePageChange = (selectedPage) => {
    const page = selectedPage.selected + 1;
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    const size = e.target.value;
    setPageSize(size);
    setCurrentPage(1); 
  };

  return isLoading ? (
    <div className="loading d-flex justify-content-center">
      <Loadercomp />
    </div>
  ) : error ? (
    <div className="d-flex justify-content-center">
      <span> Error loading data </span>
    </div>
  ) : (
    <div className="table-container">
      
      <div className="d-flex flex-wrap justify-content-end align-items-start gap-3">
    


  <div style={{ flex: 1, position: "relative" }}>
  <input
      type="date"
      className="form-control"
      style={{ fontSize: "13px" }}
      placeholder="Created At"
      value={createdAt}
      onChange={handleDateChange}
      onFocus={() => setdateFocused(true)}
      onBlur={() => setdateFocused(false)}
    />
    {!dateFocused && !createdAt && (
      <div
        style={{
          backgroundColor: "white",
          height: "26px",
          width: "176px",
          position: "absolute",
          top: "4px", // Align with input field
          left: "3px", // Align with input field
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "8px",
          pointerEvents: "none", // Allow focus on input
        }}
      >
        Created At
              </div>
    )}
  </div>

  <div style={{ flex: 1, position: "relative" }}>
    <input
      type="datetime-local"
      className="form-control"
      style={{ fontSize: "13px" }}
      value={fromDate}
      onChange={handleFromDateChange}
      onFocus={() => setFromDateFocused(true)}
      onBlur={() => setFromDateFocused(false)}
    />
    {!fromDateFocused && !fromDate && (
      <div
        style={{
          backgroundColor: "white",
          height: "26px",
          width: "176px",
          position: "absolute",
          top: "4px", // Align with input field
          left: "3px", // Align with input field
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "8px",
          pointerEvents: "none", // Allow focus on input
        }}
      >
        From Date
      </div>
    )}
  </div>

  <div style={{ flex: 1, position: "relative" }}>
    <input
      type="datetime-local"
      className="form-control"
      style={{ fontSize: "13px" }}
      value={toDate}
      onChange={handleToDateChange}
      onFocus={() => setToDateFocused(true)}
      onBlur={() => setToDateFocused(false)}
    />
    {!toDateFocused && !toDate && (
      <div
        style={{
          backgroundColor: "white",
          height: "26px",
          width: "176px",
          position: "absolute",
          top: "3px", 
          left: "3px", // Align with input field
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "8px",
          pointerEvents: "none", // Allow focus on input
        }}
      >
        To Date
      </div>
    )}
  </div>
</div>


      {/* Table */}
      <table className="styled-table mt-1">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>From Time</th>
            <th>To Time</th>
            <th>Created Date</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {Data?.results?.length > 0 ? (
            Data.results.map((item, index ) => (
              <tr key={item.id}>
              <td>{index + 1}</td>
                 <td>{new Date(item.from_time).toLocaleString()}</td>
                   <td>{new Date(item.to_time).toLocaleString()}</td>
                   <td>{new Date(item.created_at).toLocaleDateString()}</td>





                {/* <td>
                  <NavLink to={`/view-doctor`} state={{ data: item }}>
                    <BsFillEyeFill
                      style={{ paddingRight: "5px" }}
                      fontSize={21}
                      color="#00A192"
                    />
                  </NavLink>
                  <NavLink to={`/view-doctor`} state={{ data: item }}>
                    <BiEdit fontSize={16} color="#00A192" />
                  </NavLink>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} style={{ textAlign: "center", fontSize: "15px" }}>
                <span style={{ fontSize: "14px" }}>
                  No data available in table
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* Page Size Selector */}
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          Show
          <select
            value={pageSize}
            style={{ outline: "none" }}
            onChange={handlePageSizeChange}
            className="page-size-selector"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>{" "}
          entries
        </div>

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={Math.ceil(Data?.count / pageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
      </div>

  );
};

export default Table;
