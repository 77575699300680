import Breadcup from '../../components/Breadcup';
import Header from '../../components/Header';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import { useGetDoctordetailQuery } from '../../store/api/doctorapi';

const Profiledetail = () => {
  const nvg = useNavigate();
  const gettokinval = gettoken();

  const { data: Data, error, isLoading } = useGetDoctordetailQuery({ id: '1' });

  if (isLoading) {
    return (
      <div className="text-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center">
        <p>Error fetching doctor details.</p>
      </div>
    );
  }

  return (
    <div className="container-fluid py-4">
      {/* <p className="m-0 customfont">User Profile</p> */}
      <div className="dashboardcontent ">
                 {/* <Breadcup name={'Profile'} /> */}
                 <div className="container-fuild px-2 desgin1">
                     <div className="row bg-white ">
                         {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Personal Detail</div> */}
                         <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
                             <p className="m-0 customfont" >User Profile</p>

                             <div className="addnew d-block mb-2">
                                 <button className="btn text-white closebtn"><NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
                             </div>

                        </div>
                   </div>
</div>
                

      <div className="card" style={{ border: 'none' }}>
        <div className="card-body mt-4">
          <div className="row g-2">
            {/* Profile Image */}
            <div className="col-md-4 text-center">
              <img
                src={Data?.profile_pic || '/default-profile.png'}
                alt="Doctor Profile"
                className="img-fluid rounded-circle mb-1"
                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
              />
              <h5 className="m-0" style={{ color: '#00A192', padding: '4px 0px' }}>
                {Data?.accounts?.first_name || 'N/A'} {Data?.accounts?.last_name || 'N/A'} <br />
                {Data?.accounts?.designation}
              </h5>
              
            </div>

            {/* Profile Details */}
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Email:</label>
                  <p className="text-muted fs-6">{Data?.accounts?.email || 'N/A'}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Phone Number:</label>
                  <p className="text-muted fs-6">{Data?.accounts?.phone_number || 'N/A'}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Price:</label>
                  <p className="text-muted fs-6">{Data?.amount || 'N/A'}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Created At:</label>
                  <p className="text-muted fs-6">
                    {Data?.created_at ? new Date(Data.created_at).toLocaleString() : 'N/A'}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Experience:</label>
                  <p className="text-muted fs-6">{Data?.experience || 'N/A'} years</p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-semibold fs-10">Doctor Type:</label>
                  <p className="text-muted fs-6">{Data?.doctor_type || 'N/A'}</p>
                </div>
                <div className="col-12">
                  <label className="fw-semibold fs-10">About:</label>
                  <p className="text-muted fs-6">{Data?.about || 'No information provided.'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Profiledetail;