import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useGetVoiceofthedaylistQuery } from "../../../store/api/voiceofthedayapi";
import Loadercomp from "../../../components/Loadercomp";

const Table = () => {
  // const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("")
  const [createdAt, setCreatedAt] = useState("");
  const [dateFocused, setdateFocused] = useState(false);

  // Fetch data with dynamic parameters using RTK Query
  const { data, error, isLoading } = useGetVoiceofthedaylistQuery({
    page: currentPage,
    pageSize,
    createdAt,
    title,
    content

  });

  // console.log("check response", data, error, isLoading);



  const handlePageChange = (selectedPage) => {
    const page = selectedPage.selected + 1;
    setCurrentPage(page);
  };
  const handleDateChange = (e) => {
    const value = e.target.value;
    console.log("qwerty", value)
    setCreatedAt(value);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (e) => {
    const size = e.target.value;
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  return isLoading ? (
    <div className="loading d-flex justify-content-center">
      <Loadercomp />
    </div>
  ) : error ? (
    <div className="d-flex justify-content-center">
      <span> Error loading data </span>
    </div>
  ) : (
    <div className="table-container">
      {/* Search Input */}
      <div className="d-flex flex-wrap justify-content-end align-items-start gap-3">
        <div style={{ flex: 1 }}>
          <input
            type="email"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <input
            type=""
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div style={{ flex: 1, position: "relative" }}>
          <input
            type="date"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Created At"
            value={createdAt}
            onChange={handleDateChange}
            onFocus={() => setdateFocused(true)}
            onBlur={() => setdateFocused(false)}
          />
          {!dateFocused && !createdAt && (
            <div
              style={{
                backgroundColor: "white",
                height: "26px",
                width: "176px",
                position: "absolute",
                top: "4px", // Align with input field
                left: "2px", // Align with input field
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
                pointerEvents: "none", // Allow focus on input
              }}
            >
              Created AT
            </div>
          )}
        </div>
      </div>

      {/* Table */}
      <table className="styled-table mt-1">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>Title</th>
            <th>Content</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.results?.length > 0 ? (
            data.results.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.content}</td>
                <td>{new Date(item.create_date).toLocaleString()}</td>
                <td>
                  <NavLink to={`/view-voice-of-the-day`} state={{ data: item }}>
                    <BsFillEyeFill
                      style={{ paddingRight: "5px" }}
                      fontSize={21}
                      color="#00A192"
                    />
                  </NavLink>
                  <NavLink to={`/update-voice-of-the-day`} state={{ data: item }}>
                    <BiEdit fontSize={16} color="#00A192" />
                  </NavLink>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: "center", fontSize: "15px" }}>
                <span style={{ fontSize: "14px" }}>
                  No data available in table
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* Page Size Selector */}
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          Show
          <select
            value={pageSize}
            style={{ outline: "none" }}
            onChange={handlePageSizeChange}
            className="page-size-selector"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>{" "}
          entries
        </div>

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.count / pageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default Table;
