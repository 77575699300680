import React, { useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";
import { useGetMeditationCategorylistQuery } from "../../../store/api/meditation-category-api";
import { usePostMeditationMutation } from "../../../store/api/meditationapi";
import { usePostDoctorMutation } from "../../../store/api/doctorapi";

const Adddoctor = () => {
  const nvg = useNavigate();
  const [loading, setloading] = useState(false);
  const [meditationresponse, setmeditationresponse] = useState("");
  const [meditationerror, setmeditationerror] = useState("");
  const {
    data: meditationcategorylist,
    isLoading,
    refetch,
  } = useGetMeditationCategorylistQuery();
  const [Createdoctor] = usePostDoctorMutation();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    doctortype: "",
    amount: "",
    experience: "",
    about: "",
    password: "",
    profile_pic: null,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name == "mobile"){
      if (/^\d*$/.test(value)) {
      setFormData({ ...formData, [name]: value });
      }
    }else{
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFormData({ ...formData, profile_pic: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("first_name", formData.firstname);
    data.append("last_name", formData.lastname);
    data.append("email", formData.email);
    data.append("phone_number", formData.mobile);
    data.append("doctor_type", formData.doctortype);
    data.append("amount", formData.amount);
    data.append("experience", formData.experience);
    data.append("about", formData.about);
    data.append("password", formData.password);
    data.append("profile_pic", formData.profile_pic);
    const pro = Createdoctor(data);
    console.log("create api response", pro);
    setFormData({
      firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    doctortype: "",
    amount: "",
    experience: "",
    about: "",
    password: "",
    profile_pic: null,
    });
    setmeditationresponse("Uploading...");
    const response = await pro;
    if (response.data.message == "Doctor Created.") {
      setmeditationresponse("Created Successfully");
      setmeditationerror("");
      setTimeout(() => {
        setmeditationresponse("");
        nvg("/doctorlist");
      }, 3500);
    } else {
      setmeditationerror("Something Went Wrong Please Try Again");
      setmeditationresponse("");
    }
  };

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"User"} secondname={"Add User"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
                User
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/doctorlist");
                  }}
                  style={{
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {isLoading == true || loading == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <form onSubmit={handleSubmit}>
              <div
                className="row bg-white round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                }}
              >
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="firstname"
                        type="text"
                        value={formData.firstname}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your First Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="lastname"
                        type="text"
                        value={formData.lastname}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Last Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Email"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Mobile No.<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="mobile"
                        type="tel"
                        minLength={10}
                        maxLength={10}
                        value={formData.mobile}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Mobile No."
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Doctor Type<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="doctortype"
                        type="text"
                        value={formData.doctortype}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Doctor Type"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Amount<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="amount"
                        type="number"
                        value={formData.amount}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Amount"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Experience<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="experience"
                        type="number"
                        value={formData.experience}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Experience"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Profile Image<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="profile_pic"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Profile Image"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        About<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <textarea
                        name="about"
                        type="text"
                        value={formData.about}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter About You"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Password<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Your Password"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 px-4 pt-2">
                  <div className="row d-flex justify-content-end">
                    <div
                      className="px-2 d-flex justify-content-end pt-5 pb-2"
                      style={{ gap: "5px" }}
                    >
                      <button
                        type="button"
                        className="btn "
                        style={{
                          background: "#F2F2F2",
                          color: "#7C7878",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          nvg("/userlist");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn "
                        style={{
                          background: "#179F92",
                          color: "#fff",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                {meditationerror != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex pb-2 alert alert-danger"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        {meditationerror}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {meditationresponse != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex  alert alert-success"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        <span>{meditationresponse}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Adddoctor;
