
const tokenstore = (value)=>{
  localStorage.setItem('token',value)
}
const refreshtokenstore = (value)=>{
  localStorage.setItem('refresh',value)
}
const typeofuserstore = (value)=>{
  localStorage.setItem('usertype',value)
}
const currentuserid = (value)=>{
  localStorage.setItem('userid',value)
}
const sohstore = (value)=>{
  localStorage.setItem('soh',JSON.stringify(value))
}

const getsoh = ()=>{
  let data = localStorage.getItem('soh')
  return JSON.parse(data)
}

const gettoken = ()=>{
  let data = localStorage.getItem('token')
  return data
}
const getrefreshtoken = ()=>{
  let data = localStorage.getItem('refresh')
  return data
}
const gettypeofuser = ()=>{
  let data = localStorage.getItem('usertype')
  return data
}
const getcurrentuserid = ()=>{
  let data = localStorage.getItem('userid');
  return data
}

const privateurl = (value)=>{
    localStorage.setItem('privatetoken',JSON.stringify(value))
  }
const getprivateurl = ()=>{
    let data = localStorage.getItem('privatetoken')
    return JSON.parse(data)
 }

 const removeToken = ()=>{
  localStorage.removeItem('token')
    }

  export { getrefreshtoken,refreshtokenstore,privateurl,getprivateurl,tokenstore,gettoken,removeToken,getsoh,sohstore,typeofuserstore,gettypeofuser,currentuserid,getcurrentuserid }

  