import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: "https://teentogether.stackerbee.com",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserlist: builder.query({
      query: ({ page = 1, pageSize = 10, searchUsername = "",
        createdAt = null,
        email = "",
        mobilenumber = null,
        searchGender = ''
      }) => ({
        url: `/user/user-list`,
        params: {
          page,
          page_size: pageSize,
          gender: searchGender,
          nickname: searchUsername,
          mobile: mobilenumber,
          email: email,
          created_date: createdAt

        },
      }),
    }),
    getDoctordetail: builder.query({
      query: ({ id }) => ({
        url: `/doc-module/doc-profile/${id}`,
        method: 'GET'
      }),
    }),
    postDoctor: builder.mutation({
      query: (data) => ({
        url: `/doc-module/doc-create/`,
        method: 'POST',
        body: data
      })
    }),
    putVoiceoftheday: builder.mutation({
      query: ({ data, id }) => ({
        url: `/voice-day/voice-update/${id}/`,
        method: 'PUT',
        body: data
      })
    }),
  }),
})

export const { useGetUserlistQuery, useGetDoctordetailQuery, usePostDoctorMutation, usePutVoiceofthedayMutation } = userApi