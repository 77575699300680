import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: "https://teentogether.stackerbee.com",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },

});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && (result.error.status === 401 || result.error.status === 403)) {
        localStorage.removeItem('token');
    }

    return result
}


export const appointmentApi = createApi({
    reducerPath: 'appointmentApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAppointmentlist: builder.query({
            query: ({ page = 1, pageSize = 10, searchGender = "",
                searchUsername = "",
                paymentstatus = " ",
                realfromtime = null,
                realtotime = null,
                createdAt = null,searchdoctorname = "" }) => ({
                    url: `/appointmeant/appointmeant-list`,
                    params: {
                        from_date: realfromtime,
                        page,
                        page_size: pageSize,
                        gender: searchGender,
                        payment_status: paymentstatus,
                        to_date: realtotime,
                        created_date: createdAt,
                        nickname: searchUsername,
                        doctorname:searchdoctorname


                    },
                }),
        }),
        postAppointmentdoc: builder.mutation({
            query: (data) => ({
                url: `/appointmeant/appointmeant-doc`,
                method: 'POST',
                body: data
            })
        }),

        postAppointmentcreate: builder.mutation({
            query: (data) => ({
                url: `/appointmeant/appointmeant-create`,
                method: 'POST',
                body: data
            })
        }),

        postAppointmentpayment: builder.mutation({

            query: (data) => ({
                url: `/appointmeant/appointmeant-payment`,
                method: 'POST',
                body: data
            })

        }),
    })
})

export const { useGetAppointmentlistQuery, usePostAppointmentcreateMutation, usePostAppointmentpaymentMutation, usePostAppointmentdocMutation } = appointmentApi