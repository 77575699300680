import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import Loadercomp from "../../../components/Loadercomp";
import { useGetUserlistQuery } from "../../../store/api/userapi";

const Table = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dateFocused, setdateFocused] = useState(false);
  const [searchGender, setSearchGender] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [createdAt, setCreatedAT] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [email, setEmail] = useState("")
  const { data, error, isLoading } = useGetUserlistQuery({
    page: currentPage,
    pageSize,
    searchGender,
    searchUsername,
    createdAt,
    email,
    mobilenumber,
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    console.log("qwerty", value)
    setCreatedAT(value);
    setCurrentPage(1);
  };
  const handlePageChange = (selectedPage) => {
    const page = selectedPage.selected + 1;
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    const size = e.target.value;
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  return isLoading ? (
    <div className="loading d-flex justify-content-center">
      <Loadercomp />
    </div>
  ) : error ? (
    <div className="d-flex justify-content-center">
      <span> Error loading data </span>
    </div>
  ) : (
    <div className="table-container">
      {/* Search Input */}
      <div className="d-flex flex-wrap justify-content-end align-items-start gap-3">

        <div style={{ flex: 1 }}>
          <input
            type="text"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Username"
            value={searchUsername}
            onChange={(e) => setSearchUsername(e.target.value)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <input
            type="text"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Gender"
            value={searchGender}
            onChange={(e) => setSearchGender(e.target.value)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <input
            type="email"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div style={{ flex: 1 }}>
          <input
            type=""
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Contact Number"
            value={mobilenumber}
            onChange={(e) => setMobilenumber(e.target.value)}
          />
        </div>



        <div style={{ flex: 1, position: "relative" }}>
          <input
            type="date"
            className="form-control"
            style={{ fontSize: "13px" }}
            placeholder="Created At"
            value={createdAt}
            onChange={handleDateChange}
            onFocus={() => setdateFocused(true)}
            onBlur={() => setdateFocused(false)}
          />
          {!dateFocused && !createdAt && (
            <div
              style={{
                backgroundColor: "white",
                height: "26px",
                width: "176px",
                position: "absolute",
                top: "4px", // Align with input field
                left: "2px", // Align with input field
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
                pointerEvents: "none", // Allow focus on input
              }}
            >
              Created AT
            </div>
          )}
        </div>
      </div>

      {/* Table */}
      <table className="styled-table mt-1">
        <thead>
          <tr>
            <th>S.NO</th>
            {/* <th>Username</th> */}
            <th>Full Name</th>
            <th>Gender</th>
            <th>Designation</th>
            <th>Email ID</th>
            <th>Mobile No.</th>
            <th>Created Date & Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.results?.length > 0 ? (
            data.results.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.nick_name}</td>
                {/* <td>{item.account.first_name} {item.account.last_name}</td> */}
                <td>{item.gender}</td>
                <td>{NaN}</td>
                <td>{item.account.email}</td>
                <td>{item.account.phone_number}</td>
                <td>{new Date(item.account.date_joined).toLocaleString()}</td>
                <td>
                  <NavLink to={`/view-user`} state={{ data: item }}>
                    <BsFillEyeFill
                      style={{ paddingRight: "5px" }}
                      fontSize={21}
                      color="#00A192"
                    />
                  </NavLink>
                  <NavLink to={`/view-user`} state={{ data: item }}>
                    <BiEdit fontSize={16} color="#00A192" />
                  </NavLink>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center", fontSize: "15px" }}>
                <span style={{ fontSize: "14px" }}>
                  No data available in table
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* Page Size Selector */}
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          Show
          <select
            value={pageSize}
            style={{ outline: "none" }}
            onChange={handlePageSizeChange}
            className="page-size-selector"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>{" "}
          entries
        </div>

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.count / pageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default Table;
