import Table from "./Table";

const Appointmentlist = () => {
  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">Appointment List </p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div
                className="addnew d-lg-flex d-none"
                style={{ flexDirection: "column", gap: "4px" }}
              ></div>
            </div>
          </div>

          <div
            className="row bg-white pb-4 rounded-bottom table-responsive"
            style={{ paddingBottom: "7rem" }}
          >
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointmentlist;
