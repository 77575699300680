import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { usePostAvailableTimeMutation } from "../../../store/api/available-time-api";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([
    { time: "12 AM - 1 PM", selected: false },
    { time: "1 PM - 2 PM", selected: false },
    { time: "2 PM - 3 PM", selected: false },
    { time: "3 PM - 4 PM", selected: false },
    { time: "4 PM - 5 PM", selected: false },
    { time: "5 PM - 6 PM", selected: false },
    { time: "6 PM - 7 PM", selected: false },
    { time: "7 PM - 8 PM", selected: false },
    { time: "8 PM - 9 PM", selected: false },
    { time: "9 PM - 10 PM", selected: false },
    { time: "10 PM - 11 PM", selected: false },
    { time: "11 PM - 12 PM", selected: false },
    { time: "12 PM - 1 AM", selected: false },
    { time: "1 AM - 2 AM", selected: false },
    { time: "2 AM - 3 AM", selected: false },
    { time: "3 AM - 4 AM", selected: false },
    { time: "4 AM - 5 AM", selected: false },
    { time: "5 AM - 6 AM", selected: false },
    { time: "6 AM - 7 AM", selected: false },
    { time: "7 AM - 8 AM", selected: false },
    { time: "8 AM - 9 AM", selected: false },
    { time: "9 AM - 10 AM", selected: false },
    { time: "10 AM - 11 AM", selected: false },
    { time: "11 AM - 12 PM", selected: false },
  ]);

  const [success, setsuccess] = useState("");
  const [Createavailabletime] = usePostAvailableTimeMutation();
  const nvg = useNavigate();
  const handleTimeSlotSelect = (index) => {
    const updatedSlots = timeSlots.map((slot, i) =>
      i === index ? { ...slot, selected: !slot.selected } : slot
    );
    setTimeSlots(updatedSlots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      console.log("Please select a date.");
      return;
    }
    const selectedSlots = timeSlots.filter((slot) => slot.selected);

    if (selectedSlots.length == 0) {
      console.log("Please select at least one time slot.");
      return;
    }

    // Prepare JSON structure
    const timeSlotsJSON = selectedSlots
      .map((slot) => {
        const [fromTime, toTime] = slot.time.split(" - ");
        // const dateStr = selectedDate.toISOString().split("T")[0];
        const dateStr = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000).toISOString().split("T")[0];

        // Function to convert AM/PM time to 24-hour format and get ISO string
        const getTimeInISO = (timeStr) => {
          if (!timeStr) {
            throw new Error("Invalid time string");
          }

          const [time, modifier] = timeStr.split(" ");
          if (!time || !modifier) {
            throw new Error("Invalid time format");
          }

          const [hours, minutes] = time.includes(":")
            ? time.split(":").map(Number) // Split into hours and minutes if ":" exists
            : [Number(time), 0]; // Default to 0 minutes if ":" is not present

          if (isNaN(hours) || isNaN(minutes)) {
            throw new Error("Invalid time value");
          }

          let hours24 = hours;

          if (modifier === "PM" && hours < 12) hours24 += 12;
          if (modifier === "AM" && hours === 12) hours24 = 0;

          // Ensure proper date format
          const formattedTime = new Date(
            `${dateStr}T${hours24.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:00Z`
          );

          return formattedTime.toISOString();
        };

        try {
          return {
            from_time: getTimeInISO(fromTime),
            to_time: getTimeInISO(toTime),
          };
        } catch (error) {
          console.error("Error parsing time:", error);
          return null; // Return null or handle the error accordingly
        }
      })
      .filter((item) => item !== null); // Remove invalid time slots

    if (timeSlotsJSON.length === 0) {
      return;
    }
    const dataToSend = { time_slots: timeSlotsJSON };

    console.log("Prepared Data:", dataToSend);
    try {
      const response = await Createavailabletime(dataToSend);
      console.log("API Response:", response);
      if (response.data.msg == "available time updated") {
        setsuccess("Your Availability has been Saved Successfully!");
        setTimeout(() => {
          nvg("/available-time-list");
          setsuccess("");
        }, 2500);
      }
    } catch (error) {
      console.error("Error while saving availability:", error);
    }

    // Reset form
    setSelectedDate(null);
    setTimeSlots(timeSlots.map((slot) => ({ ...slot, selected: false })));
  };

  return (
    <div className="col-12">
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-start" style={{width:'80%'}}>
          <div className="" style={{width:'55%'}}>
            <div>
              <Calendar
                onChange={(date) => setSelectedDate(date)}
                value={selectedDate}
                minDate={new Date()} // Prevent past dates
              />
            </div>
          </div>

          <div className="d-flex flex-column scrollable-div" style={{width:'21%',padding:'0px 14px',backgroundColor:'#00a192'}}>
            <h3 style={{fontSize:'14px',textAlign:'center',marginTop:'16px',color:'white'}} className="underlined">Available Time Slots</h3>
            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginTop: "10px",
                  justifyContent:'center'
                }}
              >
                {timeSlots.map((slot, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => handleTimeSlotSelect(index)}
                    style={{
                      padding: "7px 13px",
                      fontSize: "12px",
                      height: "fit-content",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border:slot.selected ? '1px solid #fff' : '0px',
                      backgroundColor: slot.selected ? "#00A192" : "#ccc",
                      color: slot.selected ? "#fff" : "#fff",
                      transition: "background-color 0.3s",
                    }}
                  >
                    {slot.time}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex ">
        <button
              type="submit"
              style={{
                fontSize: "14px",
                height: "fit-content",
                padding: "8px 14px",
                backgroundColor: "#00A192",
                color: "#fff",
                border: "none",
                cursor: "pointer",
                position:'relative',
                top:'-53px',
                left:'17px'
              }}
            >
              Save Availability
            </button>
        </div>

        <div className="d-flex justify-content-between mt-3 align-items-center">
          <div style={{ width: "84%" }}>
            {success == "" ? (
              ""
            ) : (
              <div class="alert alert-success m-0" role="alert">
                {success}
              </div>
            )}
          </div>
          <dir>
            {/* <button
              type="submit"
              style={{
                fontSize: "14px",
                height: "fit-content",
                padding: "8px 14px",
                backgroundColor: "#00A192",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Save Availability
            </button> */}
          </dir>
        </div>
      </form>
    </div>
  );
};

export default Form;
